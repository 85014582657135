@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--colorFail);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }

  & legend {
    color: var(--colorGray);
    font-size: 20px;
    font-weight: var(--fontWeightBold);
    margin-bottom: 0;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 160px;
    margin: 0 0 0 auto;
  }
}
